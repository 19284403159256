<template>
  <b-container
    v-if="!isFetching"
    fluid
  >
    <BreadCrumb
      :items="[
        {
          text: 'Inicio',
          href: '/plataforma-credito',
        },
        {
          text: 'Detalhes Estabelecimento',
          href: `/plataforma-credito/estabelecimento/detalhes/${merchantId}`,
        },
        {
          text: 'Averbação',
        },
      ]"
    />
    <header-merchant />
    <b-card>
      <validation-observer
        ref="averbationForm"
        #default="{ invalid }"
      >
        <form-averbation
          :register="endorsement.register"
          :endorsement="endorsement"
          :draft="true"
        />

        <contract-specifications
          :is-percentage="endorsement.division_method === 'percentage'"
          :contract-specifications="endorsement.contract_specifications"
          :fields="endorsement.division_method === 'percentage' ? [
            { key: 'expected_settlement_date', label: 'previsão da liquidação' },
            { key: 'receivable_debtor', label: 'adquirente' },
            { key: 'payment_scheme', label: 'esquema' },
            { key: 'actions', label: 'Ações' },
          ] : [
            { key: 'expected_settlement_date', label: 'previsão da liquidação' },
            { key: 'receivable_debtor', label: 'adquirente' },
            { key: 'payment_scheme', label: 'esquema' },
            { key: 'effect_value', label: 'Valor Efetivo' },
            { key: 'actions', label: 'Ações' },
          ]"
        />

        <b-row class="justify-content-center">
          <b-button
            variant="primary"
            class="mr-2"
            @click="validateCreateAverbation"
          >
            Criar Averbação
          </b-button>

          <b-button
            variant="primary"
            :disabled="invalid"
            @click="validateAverbation"
          >
            Salvar Rascunho
          </b-button>

          <b-button
            variant="danger"
            class="ml-1"
            @click="cancelEndorsement"
          >
            Cancelar Averbação
          </b-button>
        </b-row>
      </validation-observer>
    </b-card>
  </b-container>
</template>

<script>
import {
    BContainer, BCard, BButton, BRow,
} from 'bootstrap-vue';

import {
    ValidationObserver,
} from 'vee-validate';
import FormAverbation from './Components/Form.vue';
import ContractSpecifications from './Components/ContractSpecifications/ContractSpecifications.vue';

import EndrosementDraftService from '@/services/osiris/EndrosementDraftService';
import CercEndorsementService from '@/services/osiris/Cerc/EndorsementDraftService';

import BreadCrumb from '@/views/components/BreadCrumb.vue';

import HeaderMerchant from '@/views/pages/CreditPlatform/Merchants/Details/components/Header.vue';

import TagMapper from './mappers/TAG';
import CercMapper from './mappers/CERC';

const removeSymbolsMoney = value => (typeof value === 'string'
    ? +value.replace(/[R$ .]/g, '').replace(',', '.')
    : value);

const removeSymbolsTaxId = taxId => taxId.replace(/[^a-zA-Z0-9]/g, '');

const services = {
    TAG: EndrosementDraftService,
    CERC: CercEndorsementService,
};

const mappers = {
    TAG: TagMapper,
    CERC: CercMapper,
};

export default {
    components: {
        BButton,
        BCard,
        BContainer,
        BRow,
        BreadCrumb,
        FormAverbation,
        ContractSpecifications,
        ValidationObserver,
        HeaderMerchant,
    },

    data() {
        return {
            isFetching: true,
            merchantId: this.$store.getters['creditPlatform/merchant'].id || JSON.parse(Crypto.decrypt(sessionStorage.getItem('optin'))).id,
            endorsement: {
                division_method: 'fixed',
                contract_value: 0.1,
                contract_specifications: [
                    {
                        expected_settlement_date: '',
                        receivable_debtor: '',
                        payment_scheme: '',
                        effect_value: 'R$ 1,00',
                        is_possible_edit: true,
                        is_editable: true,
                    },
                ],
                bank_account: {
                    account_type: 'CC',
                },

                id: this.$route.params.id,
            },
        };
    },

    watch: {
        isFetching(value) {
            this.isLoading(value);
        },
    },

    mounted() {
        this.getEndorsement();
        this.confirmAnAction('Confirme as informações antes de criar a averbação');
        try {
            // const merchant = !this.$store.getters['creditPlatform/merchant'].id ? JSON.parse(Crypto.decrypt(sessionStorage.getItem('optin'))) : this.$store.getters['creditPlatform/merchant'];
            // const { id: merchant_id, optin_id: opt_in_id, asset_holder, cerc_optin_id } = merchant;

            // if (!opt_in_id || !merchant_id || !asset_holder) {
            //     this.$router.push('/');
            // }

            // this.getEndorsement();
        } catch {
            // this.$router.push('/');
        }
    },

    methods: {
        async validateAverbation() {
            const isConfirmedAction = await this.confirmAnAction('Deseja realmente criar essa averbação');
            if (!isConfirmedAction) return;

            this.$refs.averbationForm.validate().then(success => {
                if (success) { this.updateEndorsement(); }
            });
        },

        async validateCreateAverbation() {
            this.$refs.averbationForm.validate().then(async success => {
                if (success) {
                    const isConfirmedAction = await this.confirmAnAction('Todas alterações devem ser salvar antes de criar a averbação, você realmente deseja criar essa averbação?');
                    if (isConfirmedAction) this.confirmEndorsement();
                }
            });
        },

        async confirmEndorsement() {
            this.isFetching = true;
            await this.updateEndorsement(true);
            const { status, data } = await services[this.endorsement.register].confirm({ id: this.$route.params.id });
            const merchant = !this.$store.getters['creditPlatform/merchant'].id ? JSON.parse(Crypto.decrypt(sessionStorage.getItem('optin'))) : this.$store.getters['creditPlatform/merchant'];
            const { id: merchantId } = merchant;

            if (status === 200) {
                this.modalSuccess('Averbação criada com sucesso');

                this.$router.push(`/plataforma-credito/estabelecimento/detalhes/${merchantId}`);
            } else if (status === 403) {
                const confirmed = await this.confirmAnAction('Não foi possível criar sua averbação pois está fora do horário de funcionamento. \n Você gostaria de criar um rascunho dela?');

                if (!confirmed) { return; }
                this.createEndorsement(true);
            } else if (status === 406 && data.message === 'Opt-In is inactive') {
                this.modalError('Opt-in está inativo');
            } else if (status === 406 && data.message === 'Specifications total value must be equal or less to contract value') {
                this.modalError('Valor das especificações de contrato juntas deve ser menor que o saldo devido');
            } else if (status === 502) {
                this.modalError(`<p>Averbação Negada</p> <p>
                    ${this.endorsement.register === 'CERC' ? data.message : ''}
                    </p>
                    `, true);
            } else {
                this.modalError('Erro ao criar averbação');
            }
            this.isFetching = false;
        },

        async getEndorsement() {
            const requests = Object.values(services).map(service => service.show(this.$route.params.id));
            try {
                const { data } = await (Promise.all(requests)).then(response => response.find(item => item.status === 200));

                const dataMapped = mappers[data.opt_in.register].toView(data);

                this.endorsement = {
                    ...dataMapped,
                    contract_specifications: dataMapped.contract_specifications.map(item => ({
                        ...item,
                        is_possible_edit: true,
                        is_editable: false,
                    })),
                };
            } catch (err) {
                this.$router.push('/error-404');
            }
            this.isFetching = false;
        },

        async cancelEndorsement() {
            this.isFetching = true;
            const response = await this.confirmAnAction('Deseja realmente cancelar essa averbação?');
            this.isFetching = false;
            if (!response) return;

            const { status } = await services[this.endorsement.register].delete({ id: this.$route.params.id });

            const responseStatus = {
                200: 'Averbação cancelada com sucesso',
                202: 'Está fora do horário de funcionamento, porém seu cancelamento foi agendado',
            };

            this.modalSuccess(responseStatus[status]);
        },

        async updateEndorsement(justUpdate = false) {
            const { endorsement } = this;
            const isPercentage = endorsement.division_method === 'percentage';

            this.isLoading(true);

            const request = {
                ...endorsement,
                percentage: isPercentage ? +endorsement.percentage : undefined,
                contract_value: removeSymbolsMoney(endorsement.contract_value),
                is_cancelled: false,
                bank_account: {
                    ...endorsement.bank_account,
                    document_number: removeSymbolsTaxId(endorsement.bank_account.document_number),
                },

                contract_specifications: endorsement.contract_specifications.map(item => ({
                    ...item,
                    effect_value: isPercentage ? undefined : removeSymbolsMoney(item.effect_value),
                    receivable_debtor: removeSymbolsTaxId(item.receivable_debtor),
                    is_editable: undefined,
                    is_possible_edit: undefined,
                })),
            };

            const { status } = await services[this.endorsement.register].update({
                id: this.$route.params.id,
                data: mappers[endorsement.register].map(request),
            });

            const responseStatus = {
                200: 'Averbação editada com sucesso',
                202: 'Está fora do horário de funcionamento, porém sua edição foi agendada',
            };
            if (!justUpdate) {
                this.isLoading(false);

                if (status === 200 || status === 202) {
                    this.modalSuccess(responseStatus[status]);
                } else {
                    this.modalError('Não foi possível editar essa averbação');
                }
            }
        },

        importExcel() {
            document.getElementById('csvfile').click();
        },
    },
};
</script>
