var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isFetching)?_c('b-container',{attrs:{"fluid":""}},[_c('BreadCrumb',{attrs:{"items":[
      {
        text: 'Inicio',
        href: '/plataforma-credito',
      },
      {
        text: 'Detalhes Estabelecimento',
        href: ("/plataforma-credito/estabelecimento/detalhes/" + _vm.merchantId),
      },
      {
        text: 'Averbação',
      } ]}}),_c('header-merchant'),_c('b-card',[_c('validation-observer',{ref:"averbationForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var invalid = ref.invalid;
return [_c('form-averbation',{attrs:{"register":_vm.endorsement.register,"endorsement":_vm.endorsement,"draft":true}}),_c('contract-specifications',{attrs:{"is-percentage":_vm.endorsement.division_method === 'percentage',"contract-specifications":_vm.endorsement.contract_specifications,"fields":_vm.endorsement.division_method === 'percentage' ? [
          { key: 'expected_settlement_date', label: 'previsão da liquidação' },
          { key: 'receivable_debtor', label: 'adquirente' },
          { key: 'payment_scheme', label: 'esquema' },
          { key: 'actions', label: 'Ações' } ] : [
          { key: 'expected_settlement_date', label: 'previsão da liquidação' },
          { key: 'receivable_debtor', label: 'adquirente' },
          { key: 'payment_scheme', label: 'esquema' },
          { key: 'effect_value', label: 'Valor Efetivo' },
          { key: 'actions', label: 'Ações' } ]}}),_c('b-row',{staticClass:"justify-content-center"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary"},on:{"click":_vm.validateCreateAverbation}},[_vm._v(" Criar Averbação ")]),_c('b-button',{attrs:{"variant":"primary","disabled":invalid},on:{"click":_vm.validateAverbation}},[_vm._v(" Salvar Rascunho ")]),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"danger"},on:{"click":_vm.cancelEndorsement}},[_vm._v(" Cancelar Averbação ")])],1)]}}],null,false,3528802290)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }